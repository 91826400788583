import React from 'react'

const TextButton = ({ handler, children }) => <button
  onClick={handler}
  style={{
    cursor: 'pointer',
    padding: 0,
    outline: 'none',
    border: 'none',
    background: 'transparent',
    color: 'white'
  }}>{children}
</button>

export default TextButton
