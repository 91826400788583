import React, {useEffect, useState} from 'react'
import creationSteps from '../../demos/create'

const TYPING_DELAY_MILLISECONDS = 100

// render a single line to the terminal output
const renderLine = async (step, lines, setLines) => {
  let previousLines = lines

  if (step.padStart) {
    previousLines.push(<br />)
  }

  // set the initial line
  setLines([...previousLines, step.line()])

  // type out the input in sequence
  let input = ''

  if (!!step.input) {
    input += ' '

    for (const letter of step.input) {
      input += letter
      setLines([...previousLines, step.line(input)])
      await new Promise(resolve => setTimeout(resolve, TYPING_DELAY_MILLISECONDS))
    }

    input += '\n'

    setLines([...previousLines, step.line(input)])
  }

  // wait for nextDelaySeconds before progressing
  await new Promise(resolve => setTimeout(resolve, step.nextDelayMilliseconds))

  return [...lines, step.line(input), <br />]
}

const Terminal = ({steps = creationSteps, expectedLines = 8}) => {
  const [lines, setLines] = useState([])
  const style = { minHeight: `${expectedLines * 2}rem` }

  useEffect(() => {
    const renderLines = async () => {
      let renderedLines = lines

      for (const step of steps) {
        renderedLines = await renderLine(step, renderedLines, setLines)
      }

      await new Promise(resolve => setTimeout(resolve, 1000))
      await renderLines()
    }

    if (!lines.length && steps) {
      renderLines()
    }
  }, [lines, steps])

  return <div className="term" style={style}>
    {lines}
  </div>
}

export default Terminal
