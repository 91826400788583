import React from 'react'

export default [
  {
    line(input = '') {
      return <code key="initial-command">
        <span className="yellow">~/platter-demo</span> on <span className="green"> main</span>
        {
          !!input
            ? <>
              <br />
              <span className="green">❯</span> {input}
            </>
            : null
        }
      </code>
    },
    input: 'platter postgres create platter-demo',
    nextDelayMilliseconds: 200
  },
  {
    line() {
      return <code key="instance-ready">
        <span className="green">&nbsp;✔</span>
        &nbsp;Instance <span className="cyan">platter-demo</span> is ready
      </code>
    },
    nextDelayMilliseconds: 500
  },
  {
    line() {
      return <code key="client-ready">
        <span className="green">&nbsp;✔</span>
        <span className="cyan">&nbsp;PlatterDemo</span> client generated at <span className="cyan">platter/postgres/node/PlatterDemo</span>
      </code>
    },
    nextDelayMilliseconds: 1000
  },
  {
    padStart: true,
    line(input = '') {
      return <code key="branch-url">
        <span className="yellow">~/platter-demo</span> on <span className="green"> main</span>
        {
          !!input
            ? <>
              <br />
              <span className="green">❯</span> {input}
            </>
            : null
        }
      </code>
    },
    input: 'psql $(platter postgres branch url main -i platter-demo)',
    nextDelayMilliseconds: 200
  },
  {
    line() {
      return <code key="psql">
        psql (13.2)<br/>
        f4507c5cq1cl2ah=&gt;<br/>
      </code>
    },
    nextDelayMilliseconds: 1000
  },
]
