import React from 'react'

export default [
  {
    line(input = '') {
      return <code key="initial-command">
        <span className="yellow">~/platter-demo</span> on <span className="green"> main</span>
        {
          !!input
            ? <>
              <br />
              <span className="green">❯</span> {input}
            </>
            : null
        }
      </code>
    },
    input: 'platter watch',
    nextDelayMilliseconds: 200
  },
  {
    line() {
      return <code key="branch-main-active">
        <span className="green">&nbsp;✔</span>
        &nbsp;Branch <span className="cyan">main</span>
        &nbsp;in instance <span className="cyan">platter-demo</span>
        &nbsp;is <span className="green">active</span>
      </code>
    },
    nextDelayMilliseconds: 500
  },
  {
    line() {
      return <code key="branch-main-client-ready">
        <span className="green">&nbsp;✔</span>
        <span className="cyan">&nbsp;PlatterDemo</span>
        &nbsp;client generated at <span className="cyan">&nbsp;platter/postgres/node/PlatterDemo</span>
      </code>
    },
    nextDelayMilliseconds: 3000
  },
  {
    line() {
      return <code key="branch-feature-branch-change">
        <span className="yellow">&nbsp;BRANCH</span>
        &nbsp;changed to <span className="green">feature-branch</span>
      </code>
    },
    nextDelayMilliseconds: 500
  },
  {
    line() {
      return <code key="branch-feature-branch-active">
        <span className="green">&nbsp;✔</span>
        &nbsp;Branch <span className="cyan">feature-branch</span>
        &nbsp;from <span className="magenta">main</span>
        &nbsp;in instance <span className="cyan">platter-demo</span>
        &nbsp;is <span className="green">active</span>
      </code>
    },
    nextDelayMilliseconds: 500
  },
  {
    line() {
      return <code key="branch-feature-branch-client-ready">
        <span className="green">&nbsp;✔</span>
        <span className="cyan">&nbsp;PlatterDemo</span>
        &nbsp;client generated at <span className="cyan">&nbsp;platter/postgres/node/PlatterDemo</span>
      </code>
    },
    nextDelayMilliseconds: 1000
  },
]
