import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import TextButton from '../elements/TextButton';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  )

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <nav className="policy-nav">
              <TextButton
                handler={e => {
                  e.preventDefault()
                  window.displayPreferenceModal() // Defined/bound by script in <head> of index.html
                }}>
                Manage Cookie Preferences
              </TextButton>
              {' | '}
              <a href="https://app.termly.io/document/privacy-policy/8d314f5c-0cb5-4708-b662-c914b85a89f5">Privacy Notice</a>
              {' | '}
              <a href="https://app.termly.io/document/terms-of-use-for-saas/7dfb47d9-8762-40aa-bdb0-3048bc0edbc6">Terms of Service</a>
              {' | '}
              <a href="https://app.termly.io/document/disclaimer/59512fb3-7036-484e-b6ee-1fe8f3d2deb9">Disclaimer</a>
            </nav>
            <div className="footer-copyright">Made by <a href="https://platter.dev">Platter</a>. © 2019-{(new Date()).getFullYear()} Boilerplatter, Inc. All rights reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer
