import React, { useState, useEffect } from 'react'
import { ForceGraph2D } from 'react-force-graph'

const MAX_NODES = 500

const INITIAL_DATA = {
  nodes: [{ id: 0 }],
  links: []
}

const generateNode = ({ nodes, links }) => {
  if (nodes.length >= MAX_NODES) {
    return { nodes, links }
  } else {
    const id = nodes.length

    const link = {
      source: id,
      target: Math.round(Math.random() * (id - 1))
    }

    return {
      nodes: [...nodes, {id}],
      links: [...links, link]
    }
  }
}

const Graph = () => {
  const [data, setData] = useState(INITIAL_DATA)

  useEffect(() => {
    Array.from(Array(50)).forEach(() => setData(generateNode))

    setInterval(() => {
      setData(generateNode)
    }, 1000)
  }, [])

  return <ForceGraph2D
  backgroundColor='#e6e6fa00'
  d3AlphaDecay={0.01}
  d3VelocityDecay={0.75}
  enableNavigationControls={false}
  enablePointerInteraction={false}
  enableZoomInteraction={false}
  enablePanInteraction={false}
  graphData={data} 
  linkColor={() => '#2086f50f'}
  linkWidth={1}
  nodeColor={() => '#2086f554'}
  nodeRelSize={3}
  showNavInfo={false} />
}

export default Graph
