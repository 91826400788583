import React, { Fragment } from 'react'
// import sections
import Hero from '../components/sections/Hero'
import FeaturesTiles from '../components/sections/FeaturesTiles'
import FeaturesSplit from '../components/sections/FeaturesSplit'
import Testimonial from '../components/sections/Testimonial'
import Cta from '../components/sections/Cta'

const Home = () => 
  <Fragment>
    <Hero />
    <FeaturesTiles />
    <FeaturesSplit invertMobile topDivider imageFill />
    <Testimonial topDivider />
    <Cta split />
  </Fragment>

export default Home
